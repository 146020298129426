
export const PermissionType = {
  MODULE: 'module',
  API: 'api',
  PAGE: 'page',
  SET: 'set',
};
export const PermissionTypeMap = {
  [PermissionType.MODULE]: '菜单',
  [PermissionType.PAGE]: '页面',
  [PermissionType.API]: '接口',
  [PermissionType.SET]: '集合',
};
export const PermissionTypeDesc = {
  [PermissionType.MODULE]: '一般用于设置 UI 可见性',
  [PermissionType.PAGE]: '可设置关联页面，页面级别权限，属于「页面权限」',
  [PermissionType.API]: '可绑定数据源接口, 绑定后用户调用接口须有该权限；也可以不绑定接口，作为应用级别权限使用',
  [PermissionType.SET]: '可设置该权限代表的权限集合，方便赋权',
};
export const API_PERMISSION_BINDING_TYPE = 'cloud-function';
export const COLLECTION_BINDING_TYPE = 'collection-cgi';
export const ApiType = {
  SCF: API_PERMISSION_BINDING_TYPE,
  CGI: COLLECTION_BINDING_TYPE,
};
export const MODULE = 'rbac';
export const ADMIN_ROLE_ID = 'project.admin';
export const GUEST_ROLE_ID = 'project.guest';
export const UNLOGIN_GUEST_ROLE_ID = 'project.unlogin';
export const BIZ_OR_PROJECT = '-_ALL_-';

export const READ_PAGE = 'read.page';
export const CREATE_PAGE = 'create.page';
export const UPDATE_PAGE = 'update.page';
export const DELETE_PAGE = 'delete.page';
export const UPLOAD_PAGE = 'upload.page';

export const APPLY_WORKFLOW_ID = 'general-permission-apply-workflow';

// 仅为菜单权限设置而保留
export const PAGE_MAPPING_PERMS = [
  READ_PAGE,
  CREATE_PAGE,
  UPDATE_PAGE,
  DELETE_PAGE,
];

export const PAGE_PERMISSIONS = [
  ...PAGE_MAPPING_PERMS,
  UPLOAD_PAGE,
];

export const PAGE_PERM_MAPPING = {
  [READ_PAGE]: '查看页面数据',
  [CREATE_PAGE]: '新建页面数据',
  [UPDATE_PAGE]: '更新页面数据',
  [DELETE_PAGE]: '删除页面数据',
  [UPLOAD_PAGE]: '上传',
};

export const pagePermissionTooltip = {
  [READ_PAGE]: {
    name: '查看',
    tooltip: '查看页面数据，可以在对应页面调用数据源接口: get/list/count/request/custom_*',
    warn: '继承自内置权限：查看应用数据',
  },
  [CREATE_PAGE]: {
    name: '新建',
    tooltip: '新建页面数据，可以在对应页面调用数据源接口: create',
    warn: '继承自内置权限：新建应用数据',
  },
  [UPDATE_PAGE]: {
    name: '更新',
    tooltip: '更新页面数据，可以在对应页面调用数据源接口: update',
    warn: '继承自内置权限：更新应用数据',
  },
  [DELETE_PAGE]: {
    name: '删除',
    tooltip: '删除页面数据，可以在对应页面调用数据源接口: delete',
    warn: '继承自内置权限：删除应用数据',
  },
  [UPLOAD_PAGE]: {
    name: '上传',
    tooltip: '可以在页面使用上传组件',
    warn: '继承自内置权限：上传应用数据',
  },
};

export const BUILT_IN_ROLES = [
  ADMIN_ROLE_ID,
  GUEST_ROLE_ID,
  UNLOGIN_GUEST_ROLE_ID,
  'project.participant',
  'page.admin',
  'page.guest',
];

export const NAMESPACED = 'perm';

export default {};
