import { ref, computed, onBeforeMount } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import { PAGE_PERMISSIONS, ADMIN_ROLE_ID, GUEST_ROLE_ID, COLLECTION_BINDING_TYPE, BIZ_OR_PROJECT, PermissionTypeMap, UNLOGIN_GUEST_ROLE_ID } from './constants';
import { isMockAsGeneralUser } from '@utils/userInfo';
import { permissionApi, organizationApi } from '@/services';
import useProjectInfo from '../useProjectInfo';
import { fetchSwitchOptions, getBizConfig, getBizEntry } from '@components/nav-widget/switch/utils';
import { AuthPlatformType } from '@/composables/useAuthSetting';
import pageLoader from '@loaders/page/loader';
import { useRouter } from '@/router/useRouter';
export const getRoleUserKey = (ru) => `${ru.userId}@${ru.bizId || ''}`;
export const checkPermission = (userView, permission, currentPageId, { givenBizId = '', givenPermList } = {}) => {
    if (!permission)
        return false;
    const { roles, isAdmin } = userView;
    if (isAdmin && !isMockAsGeneralUser())
        return true;
    const { xy_runtime_project: project, xy_vue_instance: vueInst } = window;
    const bizId = givenPermList?.length ? givenBizId : getBizEntry(project)?.value;
    const customPerms = givenPermList || vueInst?.$store?.state?.rbac?.permissions || [];
    const customPagePerms = customPerms.filter(x => x.type === 'page');
    const isMatchPagePerm = (perm, pageId) => {
        if (PAGE_PERMISSIONS.includes(perm))
            return true;
        const p = customPagePerms.find(x => x.name === perm);
        if (!p || !pageId)
            return false;
        return p.relatedPages ? p.relatedPages.split(',').includes(pageId) : true;
    };
    const perms = [];
    roles.forEach((r) => {
        if (r.bizId && r.bizId !== bizId)
            return;
        r.permissions.forEach((rp) => {
            if (isMatchPagePerm(rp.permission, rp.pageId)) {
                perms.push(`${rp.permission}-${rp.pageId}`);
            }
            else {
                perms.push(rp.permission);
            }
        });
    });
    let has = false;
    if (isMatchPagePerm(permission, currentPageId)) {
        has = perms.includes(`${permission}-${currentPageId}`);
        if (!has && PAGE_PERMISSIONS.includes(permission)) {
            const [action] = permission.split('.');
            has = perms.includes(`${action}.project`);
        }
    }
    else {
        has = perms.includes(permission);
    }
    return has;
};
export const isAdminRole = (roleName) => roleName === ADMIN_ROLE_ID;
export const isGuestRole = (roleName) => roleName === GUEST_ROLE_ID || roleName === UNLOGIN_GUEST_ROLE_ID;
export const isUnloginGuestRole = (roleName) => roleName === UNLOGIN_GUEST_ROLE_ID;
export const isBuiltInRole = (roleName) => isGuestRole(roleName) || isAdminRole(roleName);
export const useFlowPermissions = (projectId) => {
    const flowPerms = ref([]);
    const fetchFlowPerms = async (envId) => {
        const resp = await permissionApi.fetchFlowPermissionList(projectId, envId);
        flowPerms.value = resp;
    };
    return {
        flowPerms,
        fetchFlowPerms,
    };
};
export const usePermission = (projectId) => {
    const permissions = ref([]);
    const fetchPermissions = async (env) => {
        const resp = await permissionApi.fetchPermissions(projectId, env);
        permissions.value = resp.filter((i) => i.type in PermissionTypeMap);
    };
    const hasPermission = (rolePerms, role, { permission, pageId }) => {
        if (isAdminRole(role))
            return true;
        return rolePerms.some(rp => rp.permission === permission && ['', String(pageId)].includes(rp.pageId || ''));
    };
    const check = (rolePerms, role, permission, pageId) => 
    // eslint-disable-next-line implicit-arrow-linebreak
    hasPermission(rolePerms, role, { permission, pageId });
    const checkProject = (rolePerms, role, permission) => {
        const projectPerm = PAGE_PERMISSIONS.includes(permission) ? permission.replace('.page', '.project') : '';
        return !!(projectPerm && check(rolePerms, role, projectPerm, ''));
    };
    return {
        permissions,
        hasPermission,
        check,
        checkProject,
        fetchPermissions,
    };
};
export const usePageList = (projectId, env) => {
    const pageList = ref([]);
    const fetchPageList = async () => {
        const arr = await pageLoader.loadBasicList(projectId, {
            query: {
                size: 'total',
                sort: '_mtime',
                order: 'desc',
            },
        }, env);
        pageList.value = arr.map((p) => ({
            id: p.id,
            pageName: `${p.name}(${p.path})`,
            pageId: p.pageId,
            path: p.path,
            tags: p.pageTag?.split(',').filter(Boolean) ?? [],
        }));
    };
    return {
        pageList,
        fetchPageList,
    };
};
export const useFilterPermissions = (permissions, apiPermissionList) => {
    const keyword = ref('');
    const filterPermissions = computed(() => {
        const q = keyword.value.toLowerCase();
        const perms = [...permissions.value];
        const result = perms.filter(p => [p.name, p.cname].some(name => name.toLowerCase().includes(q)));
        if (apiPermissionList.value?.length) {
            result.forEach((i) => {
                const apiPermList = apiPermissionList.value.filter((ap) => ap.permission === i.name);
                if (apiPermList.length) {
                    // eslint-disable-next-line no-param-reassign
                    i.apiPermList = apiPermList;
                }
                else {
                    // eslint-disable-next-line no-param-reassign
                    i.apiPermList = null;
                }
            });
        }
        return result;
    });
    return {
        keyword,
        filterPermissions,
    };
};
export const useBuiltinPermissionList = (projectId) => {
    const builtinPermissionList = ref([]);
    const { keyword, filterPermissions } = useFilterPermissions(builtinPermissionList, ref([]));
    const fetchBuiltinPermissionList = async (env) => {
        const resp = await permissionApi.fetchBuiltinPermissionList(projectId, env);
        builtinPermissionList.value = resp;
    };
    return {
        keyword,
        filterPermissions,
        fetchBuiltinPermissionList,
    };
};
export const usePagePermissionList = (projectId) => {
    const pagePermissionList = ref([]);
    const fetchPagePermissionList = async (env) => {
        const resp = await permissionApi.fetchPagePermissionList(projectId, env);
        pagePermissionList.value = resp;
    };
    const isPageRelatedPermission = (permission, pageId) => {
        const pagePerm = pagePermissionList.value.find(x => x.name === permission);
        const isRelatedPage = pagePerm?.relatedPages
            ? pagePerm.relatedPages.split(',').includes(pageId)
            : true;
        return isRelatedPage;
    };
    return {
        pagePermissionList,
        fetchPagePermissionList,
        isPageRelatedPermission,
    };
};
export const useApiPermission = (projectId) => {
    const apiPermissionList = ref([]);
    const fetchApiPermissionList = async (env) => {
        const resp = await permissionApi.listApiBinding(projectId, env);
        apiPermissionList.value = resp;
    };
    return {
        apiPermissionList,
        fetchApiPermissionList,
    };
};
export const useCloudFuncs = (projectId) => {
    const cloudFuncs = ref([]);
    const fetchCloudFuncs = async (env) => {
        try {
            const resp = await permissionApi.fetchFuncs(projectId, env);
            cloudFuncs.value = resp;
        }
        catch (error) {
            message.error('获取云函数列表失败');
        }
    };
    return {
        cloudFuncs,
        fetchCloudFuncs,
    };
};
export const useCollectionCgis = (projectId) => {
    const cgis = ref([]);
    const fetchCollectionCgis = async (env, { q = '', size = 10 } = {}) => {
        let collections = [];
        try {
            collections = await permissionApi.fetchCollections(projectId, env, { q, size });
        }
        catch (error) {
            message.error('获取数据源接口失败');
        }
        const result = [];
        collections.forEach((c) => {
            Object.keys(c.cgiInfo).forEach((id) => {
                const info = c.cgiInfo[id];
                result.push({
                    _id: `${c.collectionId}.${id}`,
                    cgiId: id,
                    name: `${c.collectionId}.${id}`,
                    group: `${c.name}(${c.collectionId})`,
                    type: COLLECTION_BINDING_TYPE,
                    method: (info.type || 'get').toUpperCase(),
                    api: '',
                    desc: info.desc || info.name,
                });
            });
        });
        cgis.value = result;
    };
    return {
        cgis,
        fetchCollectionCgis,
    };
};
export const useRawRoleUserList = (projectId) => {
    const rawRoleUserList = ref([]);
    const fetchRawRoleUserList = async (env = 'dev') => {
        try {
            const resp = await permissionApi.fetchRoleUsers(projectId, env);
            rawRoleUserList.value = resp;
        }
        catch (error) {
            message.error('获取角色用户列表失败');
        }
    };
    return {
        rawRoleUserList,
        fetchRawRoleUserList,
    };
};
export const useRoles = (projectId) => {
    const roles = ref([]);
    const fetchRoles = async (env = 'dev') => {
        try {
            const resp = await permissionApi.fetchRoles(projectId, env);
            roles.value = resp;
        }
        catch (error) {
            message.error('获取角色列表失败');
        }
    };
    return {
        roles,
        fetchRoles,
    };
};
export const useRuntimeInfo = () => {
    const url = new URL(window.location.href);
    const env = ref(window.RUNTIME_CONFIGS?.env || url.searchParams.get('env') || 'dev');
    const isRuntimeMode = computed(() => !!window.RUNTIME_CONFIGS);
    const bizId = ref(isRuntimeMode.value ? '' : BIZ_OR_PROJECT);
    return {
        env,
        bizId,
        isRuntimeMode,
    };
};
export const isPowerReadOnlyMode = (envId) => {
    const powerInfo = window.GLOBAL_INFO.deploySettingFlag?.powerMapInfo || {};
    const info = powerInfo[envId] || powerInfo['*'] || {};
    return !!info.readonly;
};
export const useEnvChange = (projectId) => {
    const canReadPermission = ref(false);
    const canUpdatePermission = ref(false);
    const bizConfig = ref(null);
    const switchOptions = ref([]);
    const { projectInfo, fetchProjectInfo } = useProjectInfo(projectId);
    const checkReadUpdatePermission = async (env) => {
        try {
            canReadPermission.value = await permissionApi.checkPermission(projectId, env, 'read.permission');
            if (!canReadPermission) {
                message.error('无权查看当前环境权限配置');
                return;
            }
            canUpdatePermission.value = await permissionApi.checkPermission(projectId, env, 'update.permission');
        }
        catch (error) {
            message.error('对应环境疑似未发版，请先发版再访问');
        }
    };
    const fetchBizInfo = async (env) => {
        await fetchProjectInfo(env);
        if (projectInfo.value?.id !== projectId) {
            message.error('对应环境疑似未发版，请先发版再访问');
            return;
        }
        bizConfig.value = getBizConfig(projectInfo.value);
        if (bizConfig.value) {
            switchOptions.value = await fetchSwitchOptions(bizConfig.value, { projectId, env });
        }
    };
    return {
        bizConfig,
        switchOptions,
        canReadPermission,
        canUpdatePermission,
        checkReadUpdatePermission,
        fetchBizInfo,
    };
};
export const hasPanguMapping = (authSetting, env) => {
    if (authSetting?.authPlatform && authSetting.authPlatform !== AuthPlatformType.PANGU)
        return false;
    const envInfo = authSetting?.envMapping?.[env];
    if (!envInfo?.enabled)
        return false;
    return true;
};
export const hasPowerMapping = (authSetting, env) => {
    if (authSetting?.authPlatform !== AuthPlatformType.POWER)
        return false;
    const envInfo = authSetting?.envMapping?.[env];
    return !!envInfo?.enabled;
};
export const canManageInXy = (authSetting, env) => {
    if (!hasPanguMapping(authSetting, env) && !hasPowerMapping(authSetting, env))
        return true;
    const envInfo = authSetting?.envMapping?.[env];
    return envInfo?.initStatus === 'success';
};
export const useActiveTabPerm = () => {
    const activeTab = ref('');
    const activeRow = ref('');
    const { route } = useRouter();
    if (route.value?.name === 'flow-edit') {
        activeTab.value = 'flow';
        activeRow.value = route.value?.params.flowId || '';
    }
    return {
        activeTab,
        activeRow,
    };
};
export const useOrg = (record) => {
    const canCheck = ref(false);
    const valid = ref(false);
    onBeforeMount(async () => {
        if (record.disabled)
            return;
        const info = await organizationApi.fetchDeptInfo(record.userId).catch(() => null);
        canCheck.value = info !== null;
        valid.value = canCheck.value && info.some((x) => x.deptIdString === record.userId
            || x.id === record.userId);
    });
    return {
        canCheck,
        valid,
    };
};
export default {};
